import auth from './middleware/auth';
import dashboard from './modules/dashboard';
import isReported from './middleware/isReported.js';
import isNotReported from './middleware/isNotReported.js';
import companySubmitted from './middleware/companySubmitted';
import wasPaid from './middleware/wasPaid';
import guest from './middleware/guest';
import checkIsApproveCompany from './middleware/checkIsApproveCompany.js';
import isApproved from './middleware/isApproved.js';
import dayjs from 'dayjs';
import ErrorPage from '@/pages/ErrorPage.vue';
import checkUserQuestionnaire from '@/router/middleware/checkUserQuestionnaire.js';
import checkCustomUserQuestionnaire from '@/router/middleware/checkCustomUserQuestionnaire.js';
import checkCustomUserQuestionnaireWIthFacility from '@/router/middleware/checkCustomUserQuestionnaireWIthFacility.js';

const routes = [
  ...dashboard,
  {
    path: '/',
    name: 'home',
    redirect: { name: 'auth.login' },
  },
  {
    path: '/company/approved',
    name: 'company.approved',
    beforeEnter: [auth, isApproved],
    component: ErrorPage,
    meta: {
      error:
        'Thank you for your request to activate Kooling Reg-X! Your account will be verified and activated within 24 hours.',
    },
  },
  {
    path: '/company/:company/pscl-print',
    name: 'company.pscl-print',
    meta: {
      title: `Report-${dayjs().format('DD/MM/YYTHH-mm')}`,
    },
    component: () => import('@/pages/pscl/Print.vue'),
    beforeEnter: auth,
  },
  {
    path: '/company',
    component: () => import('@/layouts/SimpleLayout.vue'),
    beforeEnter: [auth, checkIsApproveCompany],
    children: [
      {
        path: '',
        component: () => import('@/pages/CompanyForm/InformationForm.vue'),
        name: 'company.form.page',
        children: [
          {
            path: '',
            name: 'company.form.one',
            component: () => import('@/pages/CompanyForm/steps/StepOne.vue'),
            meta: {
              progress: 5,
              page: 'one',
              title: 'Company Profile',
            },
          },
          {
            path: '2',
            component: () => import('@/pages/CompanyForm/steps/StepTwo.vue'),
            name: 'company.form.two',
            meta: {
              progress: 10,
              page: 'two',
              title: 'Resources for Employees',
            },
          },
          {
            path: '3',
            component: () => import('@/pages/CompanyForm/steps/StepThree.vue'),
            name: 'company.form.three',
            meta: {
              progress: 15,
              page: 'three',
              title: 'Incentives, Parking Areas and Endowments.',
            },
          },
          {
            path: '4',
            component: () => import('@/pages/CompanyForm/steps/StepFour.vue'),
            name: 'company.form.four',
            meta: {
              progress: 20,
              page: 'four',
              title: 'Transportation Offer',
            },
          },
          {
            path: '5',
            component: () => import('@/pages/CompanyForm/steps/StepFive.vue'),
            name: 'company.form.five',
            meta: {
              progress: 20,
              page: 'five',
              title: 'Home Work Travel Analysis',
            },
          },
        ],
      },
      {
        path: 'payment',
        component: () => import('@/pages/CompanyForm/payment/PaymentPage.vue'),
        name: 'company.form.payment',
        beforeEnter: [companySubmitted, wasPaid],
        meta: {
          progress: 25,
          meta: {
            title: 'Payment',
          },
        },
      },
      {
        path: 'payment-success',
        component: () =>
          import('@/pages/CompanyForm/payment/PaymentSuccess.vue'),
        name: 'company.form.payment-success',
        meta: {
          progress: 25,
        },
      },
      {
        path: 'upload',
        component: () => import('@/pages/CompanyForm/payment/UploadPage.vue'),
        name: 'company.form.upload',
        beforeEnter: [companySubmitted, isReported],
        meta: {
          progress: 25,
          title: 'Employees upload',
        },
      },
      {
        path: 'questions',
        component: () =>
          import('@/pages/CompanyForm/payment/QuestionsPage.vue'),
        name: 'company.form.questions',
        beforeEnter: [companySubmitted, isReported],
        meta: {
          progress: 25,
        },
      },
      {
        path: 'success',
        component: () => import('@/pages/CompanyForm/ManualSuccess.vue'),
        name: 'company.form.manual-success',
        beforeEnter: [isReported],
        meta: {
          progress: 25,
        },
      },
      {
        path: 'design',
        component: () => import('@/pages/design/DesignPage.vue'),
        children: [
          {
            path: '',
            name: 'design',
            component: () => import('@/pages/design/ChartsPage.vue'),
            meta: {
              progress: 35,
              title: 'Design',
            },
          },
          {
            path: 'create',
            name: 'design.create',
            component: () => import('@/pages/design/CreateTemplate.vue'),
            meta: {
              progress: 35,
            },
            beforeEnter: [isReported],
          },
          {
            path: ':strategyId',
            name: 'design.edit',
            component: () => import('@/pages/design/EditTemplate.vue'),
            beforeEnter: [isReported],
            meta: {
              progress: 35,
            },
          },
        ],
        beforeEnter: companySubmitted,
      },
      {
        path: '/pscl',
        component: () => import('@/pages/pscl/PsclRoot.vue'),
        children: [
          {
            path: '',
            name: 'company.pscl',
            component: () => import('@/pages/pscl/PSCLPage.vue'),
            meta: {
              progress: 55,
              title: 'PSCL',
            },
          },
          {
            path: 'success',
            name: 'company.pscl.success',
            component: () => import('@/pages/pscl/PSCLSuccess.vue'),
            meta: {
              progress: 55,
            },
          },
        ],
        beforeEnter: companySubmitted,
        meta: {
          progress: 55,
        },
      },

      {
        path: '/monitoring',
        name: 'company.monitoring',
        component: () => import('@/pages/monitoring/MonitoringPage.vue'),
        beforeEnter: isNotReported,
        meta: {
          progress: 85,
        },
      },
      {
        path: '/profile',
        name: 'company.profile',
        component: () => import('@/pages/CompanyForm/ProfilePage.vue'),
      },
      {
        path: '/suggestions',
        name: 'company.suggestions',
        component: () => import('@/pages/CompanyForm/CompanySuggestions.vue'),
      },
    ],
  },
  {
    path: '/q/:linkId',
    name: 'user.custom-questionnaire',
    //    component: () => import('@/pages/CompanyForm/UserCustomQuestionnaire.vue'),
    beforeEnter: checkCustomUserQuestionnaire,
  },
  {
    path: '/qc/:companyToken',
    name: 'user.company.custom-questionnaire',
    beforeEnter: checkCustomUserQuestionnaireWIthFacility,
    component: () =>
      import(
        '@/pages/CompanyForm/CreateQuestionnaireLinkWithSelectFacility.vue'
      ),
  },
  {
    path: '/user-questionnaire',
    component: () => import('@/layouts/PublicLayout.vue'),
    meta: {
      showLocale: true,
    },
    children: [
      {
        name: 'user.questionnaire',
        path: '',
        beforeEnter: checkUserQuestionnaire,
        component: () => import('@/pages/CompanyForm/UserQuestionnaire.vue'),
      },
      {
        name: 'user.questionnaire.success',
        path: 'success',
        component: () => import('@/pages/CompanyForm/PublicSuccess.vue'),
      },
    ],
  },
  {
    path: '/error',
    name: 'error',
    component: ErrorPage,
  },
  {
    path: '/register',
    beforeEnter: guest,
    meta: {
      title: 'Register',
    },
    children: [
      {
        path: '',
        component: () => import('@/pages/auth/CompanyRegister.vue'),
        name: 'auth.register',
      },
      {
        path: ':city',
        component: () => import('@/pages/auth/CompanyRegister.vue'),
        name: 'auth.register.city',
      },
    ],
  },
  {
    path: '/login',
    beforeEnter: guest,
    meta: {
      title: 'Login',
    },
    children: [
      {
        path: '',
        name: 'auth.login',
        component: () => import('@/pages/auth/LoginPage.vue'),
      },
      {
        path: ':city',
        name: 'auth.login.city',
        component: () => import('@/pages/auth/LoginPage.vue'),
      },
    ],
  },
  {
    path: '/reset-password',
    component: () => import('@/layouts/PublicLayout.vue'),
    beforeEnter: guest,
    children: [
      {
        path: '',
        name: 'auth.reset',
        component: () => import('@/pages/auth/ResetPassword.vue'),
      },
    ],
  },
  {
    path: '/terms-and-conditions',
    component: () => import('@/layouts/DefaultLayout.vue'),
    children: [
      {
        path: '',
        name: 'terms-and-conditions',
        component: () => import('@/pages/TermsAndConditions.vue'),
        meta: {
          title: 'Terms And Conditions',
        },
      },
    ],
  },
  {
    path: '/privacy-policy',
    component: () => import('@/layouts/DefaultLayout.vue'),
    children: [
      {
        path: '',
        name: 'privacy-policy',
        component: () => import('@/pages/PrivacyPolicy.vue'),
        meta: {
          title: 'Privacy Policy',
        },
      },
    ],
  },
  {
    path: '/data-processing-addendum',
    component: () => import('@/layouts/DefaultLayout.vue'),
    children: [
      {
        path: '',
        name: 'data-processing-addendum',
        component: () => import('@/pages/DataProcessingAddendum.vue'),
        meta: {
          title: 'Data Processing Addendum',
        },
      },
    ],
  },
  {
    path: '/:catchAll(.*)*',
    component: () => import('@/pages/ErrorNotFound.vue'),
  },
];

export default routes;
